import React, { useState } from "react";
import "./App.css";

import { Configuration, OpenAIApi } from "openai";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import 'react-dates/initialize';
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import Responsive from "react-responsive"; 
import ReactGA from 'react-ga';

  
  const TRACKING_ID = process.env.REACT_APP_track; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);

const configuration = new Configuration({
  
  apiKey: process.env.REACT_APP_openaikey
  
});
const openai = new OpenAIApi(configuration);


function generatePrompt(destination, accommodation, arrivalDate, departureDate) {
  
  return `suggest itinerary for ${destination}. Arrival date is ${arrivalDate} and departure Date is ${departureDate}. 
  show the time and money estimates only if you are confident. Be practical in your approch. 
  Also suggest what to pack for the trip according the weather prediction. Must provide travelling tips from the travel blogs from internet.
  use maximum tokens`
  }

const App = () => {
  const [destination, setDestination] = useState("");
 
  //const [arrivalDate, setArrivalDate] = useState("");
  //const [departureDate, setDepartureDate] = useState("");
  const [responseData, setResponseData] = useState("");
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [focusedInput, setFocusedInput] = React.useState();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleDestinationChange = (event) => {
    setDestination(event.target.value);
  };
 



  // const handleArrivalDateChange = (event) => {
  //   const today = new Date().toISOString().substr(0, 10);
  //   const selectedDate = event.target.value;

  //   if (selectedDate < today) {
  //     setResponseData("Arrival date can not be before today's date");
  //   } else {
  //     setArrivalDate(selectedDate);
  //   }
  // };

  // const handleDepartureDateChange = (event) => {
  //   setDepartureDate(event.target.value);
  // };

  const handleSubmit = async (event) => {

    ReactGA.event({
      category: 'Form',
      action: 'Submit',
      label: 'My Form',
    });
    //accommodation = startDate._d


    setLoading(true);
    if (startDate && endDate) {
      // Dates have been selected, can proceed with form submission
      console.log('Form submitted with start date', startDate, 'and end date', endDate);
    } else {
      // Dates have not been selected, show error message
      setFormSubmitted(true);
    }
    
    event.preventDefault();

   // const today = new Date().toISOString().substr(0, 10);
    // if (arrivalDate < today) {
    //   setResponseData("Arrival date can not be before today's date");
    //   return;
    // }
    // if (departureDate < arrivalDate) {
    //   setResponseData("Departure date can not be before arrival date");
    //   return;
    // }
    
    try {
      async function test(destination, accommodation, arrivalDate, departureDate)
      {
        console.log("we are here:-------------------------------",destination)
        
        try {
        const completion = await openai.createCompletion({
          model: "text-davinci-003",
          prompt: generatePrompt(destination, accommodation, arrivalDate, departureDate),
          temperature: 0.6,
          max_tokens: 3950
        });

         const result = completion.data.choices[0].text;
          
    

         // console.log("this is front end side :- ,", result);
          setLoading(false);
          setResponseData(result);

      } catch(error) {
        // Consider adjusting the error handling logic for your use case
        if (error.response) {
          //console.error("your internet is down chekc it", error.response.data);
          setResponseData(error.response.data);
          setLoading(false);
          //res.status(error.response.status).json(error.response.data);
        } else {
          setResponseData("error in retriving data, try again later");
          setLoading(false);
          //console.error(`Error with OpenAI API request: }`);
          // res.status(500).json({
          //   error: {
          //     message: 'An error occurred during your request.',
          //   }
          // });
        }
      }}

      setLoading(true);

      test(destination, null , startDate.toDate().toDateString(), endDate.toDate().toDateString())  
      // setResponseData(data.message);
    } catch (error) {
      setLoading(false);
      
    }
  };

  return (

    
    <div className="App">
      <header>
        
      <h1 style={{ fontFamily: "Playfair Display Medium 500"}}>
          <span style={{ fontSize: "48px", color: "#E85A4F" }}>Trip</span>
          <span style={{ fontSize: "32px" }}>planner</span>
          <span style={{ fontSize: "48px", color: "#E85A4F" }}>HQ</span>
          <span>.com</span>
        </h1>
        <p
          style={{
            fontSize: "20px",
            color: "#E98074",
            fontWeight: "bold",
            alignContent: "center",
          }}
        >
          Say goodbye to frustration, hello to seamless travel planning!
        </p>
      </header>
      
      <div style={{ backgroundColor: "#D9B08C", padding: "15px"}}>
      
      <form onSubmit={handleSubmit}>



        
        <h4>Get your travel itinerary</h4>


              <TextField
                required
                id="standard-required"
                variant="filled"
                label="Travelling to?"
                value={destination}
                onChange={handleDestinationChange}
                InputLabelProps={{
                  style: { fontSize: '17px', color: 'black' }
                }}
              />


            <br/>



          {/* <label>
            Arrival:
            <input
              type="date"
              name="arrivalDate"
              value={arrivalDate}
              onChange={handleArrivalDateChange}
              min={new Date().toISOString().substr(0, 10)}
              required
            />
          </label>


          <br />
          <label>
            Departure:
            <input
              type="date"
              name="departureDate"
              value={departureDate}
              onChange={handleDepartureDateChange}
              min={arrivalDate}
              required
            />
          </label> */}
          <br />

          <Mobile> 
  
  <DateRangePicker 
  startDate={startDate} 
  startDateId="start-date" 
  endDate={endDate} 
  endDateId="end-date" 
  onDatesChange={({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }}
  focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} numberOfMonths={1}
  startDatePlaceholderText="Arrival*"
        endDatePlaceholderText="Departure*"
        mobileLayout={true}
        startDateInputProps={{ style: { fontSize: '18px', color: 'black' } }}
        endDateInputProps={{ style: { fontSize: '18px', color: 'black' } }}
  orientation="horizontal" 
  /> 


  </Mobile> 



  <Default> 
  <div style={{ margin: 0, padding: 0 }}>
        <DateRangePicker
        startDate={startDate}
        startDateId="start-date"
        endDate={endDate}
        endDateId="end-date"
        onDatesChange={({ startDate, endDate }) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} 
        startDatePlaceholderText="Arrival*"
        endDatePlaceholderText="Departure*"
        
        startDateInputProps={{ style: { fontSize: '18px', color: 'black' } }}
        endDateInputProps={{ style: { fontSize: '18px', color: 'black' } }}
        />
</div>
</Default>

       <br />
       <br />

       {formSubmitted && (!startDate || !endDate) && (
       
      <div className="error-message">Please select both Arrival and Departure <br/></div>
    )}
         
          <Button type="submit" variant="contained" disabled={loading}>
            
            {loading ? (
             
              <div className="loading-message">
              Please wait while we prepare your itinerary...  
                <div className="spinner"></div>
                
              </div>
            ) : (
              <div>submit</div>
            )}
            
          </Button>

          
          
          <div style={{ whiteSpace: "pre-wrap", maxWidth: "100%", overflowX: "auto" }}>
              {responseData}
          </div>
         
        </form>
            
           
      </div>
    </div>
  );
};

export const Mobile = (props) => <Responsive {...props} maxWidth={767} />;
export const Default = (props) => <Responsive {...props} minWidth={768} />;

export default App;
